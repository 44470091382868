export default {
  apiKey: "AIzaSyDVtapTu425kTkURN-6vkGFLYft-S-GIdA",

  authDomain: "paranaturalia-54ca6.firebaseapp.com",

  projectId: "paranaturalia-54ca6",

  storageBucket: "paranaturalia-54ca6.appspot.com",

  messagingSenderId: "56013494558",

  appId: "1:56013494558:web:8e9822b19bbfb2fc3e910f",

  measurementId: "G-2NMRYBK3M8"

};
